<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
          <!--설비유형 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-equip-class type="search" name="equipmentTypeCd" v-model="searchParam.equipmentTypeCd" />
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비유형별 설비특성 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :selection="popupParam.type"
      rowKey="equipmentTypeSpecId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-spec-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          equipmentCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        equipmentTypeCd: '',
        equipmentCd: '',
      },
      grid: {
        merge: [
          // { index: 0, colName: 'equipmentTypeCd' },
          // { index: 1, colName: 'equipmentTypeCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'item',
            field: 'item',
            label: '항목',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemDesc',
            field: 'itemDesc',
            label: '항목설명',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'itemUnit',
            field: 'itemUnit',
            label: '단위',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '600px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 450;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      if (this.popupParam.equipmentCd) {
        this.searchParam.equipmentCd = this.popupParam.equipmentCd;
      }
      // url setting
      this.listUrl = selectConfig.mdm.equipment.class.item.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비특성을 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
